@import '../../styles/variables';

.errorToast {
  padding: 11.2px 22.4px;
  border-radius: 22.4px;
  background-color: $userInputText;
  font-size: 14px;
  font-weight: 500;
  line-height: 130%;
  color: $white70;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 80%;
  word-break: break-word;
  text-align: center;
  position: fixed;
  z-index: 11;
  left: 50%;
  transform: translateX(-50%);
}

.bottom {
  bottom: 100px;
}

.top {
  top: 72px;
}